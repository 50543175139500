import React from 'react'
import type { AppProps } from 'next/app'
import * as Sentry from '@sentry/node'
import Script from 'next/script'

import '../styles/globals.scss'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

function MyApp(props: AppProps) {
  const { Component, pageProps } = props
  const err = (props as any).err

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <>
      {/* Pre-load embedly javascript so videos show up on first page render */}
      <Script src="//cdn.embedly.com/widgets/platform.js" strategy="beforeInteractive"></Script>

      <Component {...pageProps} err={err} />
    </>
  )
}

export default MyApp
